import React, {useContext, useState} from 'react';
import { Redirect } from 'react-router-dom';
import '../App.css';
// import '../style/Login.css'
import { GQLClient } from 'gql-fetch';

import { AuthContext } from '../context/auth';

const client = new GQLClient('http://localhost:3001/');


function Login(props) {

  const context = useContext(AuthContext);

  var [username, setUsername] = useState('');
  var [password, setPassword] = useState('');
  var [token, setToken] = useState(context.user ? context.user.token : '');

  const UsernameInputDidChange = e => {
    setUsername(e.target.value);
  }

  const PasswordInputDidChange = e => {
    setPassword(e.target.value);
  }

  const SubmitLogin = () => {
    // Send Login Req to GQL
    client.fetch(`mutation {
      login(username: "${username}", password: "${password}") {
        id
        token
        username
      }
    }`)
    .then(res => {
      if(res.json.data !== null) {
        // Login Successful, set token state to token returned by API, redirect to home
        setToken(res.json.data.login.token)
        context.login(res.json.data.login);
        window.location.pathname = "";
      }
      else {
        // TODO: Login Failed
        console.error('Login Failed')
        setToken('Login Failed')
      }
    });
  }

  return context.user ? (<Redirect to="/home"> Already Logged In</Redirect>) : (
    <div className="Login">
      <input type="text" className="LoginUsernameField" onChange={UsernameInputDidChange}/>
      <input type="password" className="LoginPasswordField" onChange={PasswordInputDidChange}/>
      <input type="submit" className="LoginSubmitButton" onClick={SubmitLogin}/>
      <p className="registerLink"> Don't have an account yet? <a href="/register">Sign Up!</a> </p>
    </div>
  );
}

export default Login;
