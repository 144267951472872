import React from 'react';
import '../App.css';
// import '../style/Jumpstart.css'

function Home() {
  return (
    <div className="Home">
      <h1><span role="img" aria-label="Plumber Emoji" className="plumberEmoji">👳🏿‍♂️</span></h1>
      <h1>coming soon</h1>
      <p>dont try to sign up its broken</p>
    </div>
  );
}

export default Home;
