import React, {useEffect, useContext, useState} from 'react';
import { Redirect } from 'react-router-dom';
import '../App.css';
// import '../style/Feed.css'
import { GQLClient } from 'gql-fetch';

import Post from '../components/Post'

import { AuthContext } from '../context/auth';

const client = new GQLClient('http://localhost:3001/');


function Feed(props) {

    const context = useContext(AuthContext);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const res = await client.fetch(`query {
                getPosts {
                    id
                    body
                    createdAt
                    username
                }
            }`)
            setPosts(res.json.data.getPosts)
        }
        fetchData()
    }, [])

    const newPostButton = () => {
        alert('New POst');
    }

    return context.user == null ? (<Redirect to="/login">Not Logged In</Redirect>) : (
        <div className="Feed">
            <div className="controls">
                <button onClick={newPostButton}>+ New Post</button>
            </div>
            <div className="posts">
                {posts.map((post) => <Post key={post.id} data={post} />)}
            </div>
        </div>
    );
}

export default Feed;
