import React, {useEffect, useContext, useState} from 'react';
import '../App.css';
// import moment from 'moment';


function Post({ data }) {

  return (
    <div className="Post">
        <p className="post-Body">{ data.body }</p>
        <p className="post-createdAt">{ data.createdAt }</p>      
        <p className="post-username">{ data.username }</p>
    </div>
  );
}

export default Post;
