import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
// import './App.css';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import NoMatch from './pages/NoMatch';
import Profile from './pages/Profile'
import Feed from './pages/Feed'

import { AuthProvider, AuthContext } from './context/auth';

function App(props) {

  var PWAPreventBrowserOpen = event => {
    // Only run for iOS full screen apps
    if (('standalone' in window.navigator) && window.navigator.standalone) {
        // Only run if link is an anchor and points to the current page
        if ( event.target.tagName.toLowerCase() !== 'a' || event.target.hostname !== window.location.hostname || event.target.pathname !== window.location.pathname || !/#/.test(event.target.href) ) return;

        // Open link in same tab
        event.preventDefault();
        alert('hi')
        window.location = event.target.href;
    }
  }

  return (
    <div className="App">
      <AuthProvider>
        <Router>
            {/* <Switch> */}
              <div className={`nav page-${window.location.pathname.substring(1) === "" ? "home" : window.location.pathname.substring(1)}`}>
                <a href="/" className="navHomeLink" onClick={PWAPreventBrowserOpen}>Home</a>
                <AuthContext.Consumer>
  {context => context.user !== null ? (<div><a href={`/@${context.user.username}`} onClick={PWAPreventBrowserOpen}>Logged in as @{context.user.username}</a> <a href="/feed" className="" onClick={PWAPreventBrowserOpen}>Feed</a></div>) : <a href="/login" onClick={PWAPreventBrowserOpen}>Log In</a>}
                </AuthContext.Consumer>
              </div>
              <div className="page">
                {/* <p>Hi, { user }</p> */}
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/@:username" component={Profile} />
                <Route exact path="/feed" component={Feed} />
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={()=>{ window.location.pathname = "/" }} />
                {/* <Route component={NoMatch} /> */}
              </div>
            {/* </Switch> */}
          </Router>
        </AuthProvider>
    </div>
  );
}

export default App;
