import React, {useContext, useState} from 'react';
import { Redirect } from 'react-router-dom';
import '../App.css';
// import '../style/Register.css'
import { GQLClient } from 'gql-fetch';

import { AuthContext } from '../context/auth';

const client = new GQLClient('http://localhost:3001/');


function Register(props) {

  const context = useContext(AuthContext);

  var [email, setEmail] = useState('');
  var [username, setUsername] = useState('');
  var [password, setPassword] = useState('');
  var [token, setToken] = useState(context.user ? context.user.token : '');

  const EmailInputDidChange = e => {
    setEmail(e.target.value.toString())
  }
  
  const UsernameInputDidChange = e => {
    setUsername(e.target.value);
  }

  const PasswordInputDidChange = e => {
    setPassword(e.target.value);
  }

  const SubmitRegister = () => {
    // Send Register Req to GQL
    client.fetch(`mutation {
      register(email: ${email} username: "${username}", password: "${password}") {
        id
        token
        username
      }
    }`)
    .then(res => {
      if(res.json.data !== null) {
        // Register Successful, set token state to token returned by API, redirect to home
        // setToken(res.json.data.register.token)
        // context.login(res.json.data.register);
        // window.location.pathname = "";
        console.log(res)
      }
      else {
        // TODO: Register Failed
        console.error('Register Failed')
        setToken('Register Failed')
      }
    });
  }

  return context.user ? (<Redirect to="/home"> Already Logged In</Redirect>) : (
    <div className="Register">
      <input type="email" className="RegisterEmailField" onChange={EmailInputDidChange}/>
      <input type="username" className="RegisterUsernameField" onChange={UsernameInputDidChange}/>
      <input type="password" className="RegisterPasswordField" onChange={PasswordInputDidChange}/>
      <input type="submit" className="RegisterSubmitButton" onClick={SubmitRegister}/>
      <p className="loginLink"> Already have an account? <a href="/login">Sign In!</a> </p>
    </div>
  );
}

export default Register;
